// import crypto, { BinaryLike } from 'crypto';

import { request } from '../axios';

export const getUserProfile = async (idx: number) => {
    try {
        const res = await request('GET', `/users/${idx}/profile`);
        if (res.isSuccess) {
            return res.result;
        }
    } catch (err) {
        console.log(`Can't Get User Profile : idx is ${idx}`);
    }

    return null;
};

function hexStringToUint8Array(): Uint8Array {
    const bytes = new Uint8Array(
        '35313e63b5538d43ad7779598a68c6e11255cf4be3cb2b0878074be37150a292'.length / 2
    );
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(
            '35313e63b5538d43ad7779598a68c6e11255cf4be3cb2b0878074be37150a292'.substr(i * 2, 2),
            16
        );
    }
    return bytes;
}

export const getHashMemberId = async (memberId: string | undefined) => {
    if (memberId === undefined) {
        return;
    }

    // 문자열을 ArrayBuffer로 변환
    const encoder = new TextEncoder();
    const data = encoder.encode(memberId);

    const key = await crypto.subtle.importKey(
        'raw',
        hexStringToUint8Array(),
        { name: 'HMAC', hash: { name: 'SHA-256' } },
        false,
        ['sign']
    );

    try {
        // SHA-256 해시 생성
        const signature = await crypto.subtle.sign('HMAC', key, data);

        // ArrayBuffer를 16진수 문자열로 변환
        const hashArray = Array.from(new Uint8Array(signature));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

        return hashHex;
    } catch (err) {
        console.error('Error generating hash:', err);
        return null;
    }
};
