import { useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
// import { useRouter } from 'next/router';
import { useLocation } from 'react-router-dom';

import { getUserProfile, getHashMemberId } from '../initChannelTalk';

import { isDisabledChatbotPath } from '../ChatbotPath';

export default function useChannelTalk() {
    // const router = useRouter();
    const location = useLocation();
    const userIdx = Cookies.get('userIdx');
    const isChannelTalkBooted = Cookies.get('x-veil-id');

    const getinitialChannelTalk = useCallback(async () => {
        if (isChannelTalkBooted) {
            return;
        }

        const profile = userIdx !== undefined ? await getUserProfile(Number(userIdx)) : null;

        const userProfileConfig = profile
            ? {
                  memberId: userIdx,
                  memberHash: (await getHashMemberId(userIdx)) || undefined,
                  profile,
              }
            : {};

        import('../ChannelTalkService')
            .then(({ default: ChannelService }) => {
                ChannelService.boot({
                    language: 'ko',
                    pluginKey: '3dfc12a8-c143-4521-a2d3-69a874bfe840',
                    ...userProfileConfig,
                });
                if (isDisabledChatbotPath(location.pathname)) {
                    ChannelService.hideChannelButton();
                    ChannelService.hideMessenger();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const showChannelTalk = useCallback(() => {
        window?.ChannelIO?.('showChannelButton');
    }, []);

    const hideChannelTalk = useCallback(() => {
        window?.ChannelIO?.('hideChannelButton');
        window?.ChannelIO?.('hideMessenger');
    }, []);

    useEffect(() => {
        if (isChannelTalkBooted) {
            return;
        }

        getinitialChannelTalk();
    }, []);

    useEffect(() => {
        if (isDisabledChatbotPath(location.pathname)) {
            hideChannelTalk();
            return;
        }
        showChannelTalk();
    }, [location.pathname]);
}
