/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalDispatch, openedModalState } from 'src/recoil/modal/modalState';

export const useModals = () => {
    const setOpenedModals = useSetRecoilState(openedModalState);
    const { open, close } = useRecoilValue(modalDispatch);

    const openModal = (Component: React.FC<any>, props: object) => {
        open(Component, props, setOpenedModals);
    };

    const closeModal = (Component: React.FC<any>) => {
        close(Component, setOpenedModals);
    };

    return { openModal, closeModal };
};
