import { atom } from 'recoil';
import { ModalDispatch, OpenedModal } from 'src/@types/modal';

export const openedModalState = atom<OpenedModal[]>({
    key: 'modalStates',
    default: [],
});

export const modalDispatch = atom<ModalDispatch>({
    key: 'modelDispatch',
    default: {
        open: (Component, props, setOpenedModal) => {
            setOpenedModal((openedModal) => [...openedModal, { Component, props }]);
        },
        close: (Component, setOpenedModal) => {
            setOpenedModal((openedModal) =>
                openedModal.filter((modal) => modal.Component !== Component)
            );
        },
    },
});
