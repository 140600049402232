import ComponentLoader from './ComponentLoader';

import styles from './styles.module.scss';

type StateType = 'fill' | 'default';

/**
 *
 * // 기본값은 화면 중앙에 위치합니다.
 * @param fill  Parent Component 영역에 따라 위치가 달라짐
 * @param default 화면 중앙에 위치하고 Loader만 보이도록 (기본값)
 */

export const EngineeoLoader = ({ state = 'default' }: { state?: StateType }) => {
    const LoaderWrapper =
        state === 'fill' ? styles.EngineeoLoaderWrapper_fill : styles.EngineeoLoaderWrapper_default;

    return (
        <div className={LoaderWrapper}>
            <ComponentLoader />
        </div>
    );
};

export default EngineeoLoader;
