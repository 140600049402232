import { useEffect } from 'react';
import { naverAnalyticsConfigScript } from './naverAnalyticsConfigScript';

const NaverAnalyticsConfig = () => {
    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') return;

        // 외부 스크립트 삽입
        const script = document.createElement('script');
        script.src = 'https://wcs.naver.net/wcslog.js';
        script.async = true;
        document.body.appendChild(script);

        // 내부 스크립트 삽입
        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = naverAnalyticsConfigScript;
        document.body.appendChild(inlineScript);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(inlineScript);
        };
    }, []);

    return null;
};

export default NaverAnalyticsConfig;
