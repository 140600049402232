declare global {
    interface Window {
        __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
    }
}

export function disableReactDevTools() {
    // Check if the React Developer Tools global hook exists
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') {
        return;
    }

    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        if (prop === 'renderers') {
            // initialise this with an empty `Map`,
            // else it will throw an error in console

            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
        } else {
            // Replace all of its properties with a no-op function or a null value
            // depending on their types

            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function' ? () => {} : null;
        }
    }
}
