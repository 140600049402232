import React from 'react';
import styles from './styles.module.scss';

const RootErrorBoundary = () => {
    return (
        <div className={styles.errorBoundaryRoot}>
            <div className={styles.title}>오류가 발생했습니다.</div>
            <div className={styles.content}>
                <div>페이지 새로고침으로 해결되지 않는다면,</div>
                <div>우측 하단 채널톡을 통해 문의하시기 바랍니다</div>
            </div>
            <button
                onClick={() => {
                    window.location.reload();
                }}
                className={styles.refreshBtn}
            >
                새로고침
            </button>
        </div>
    );
};

export default RootErrorBoundary;
