import { atom, selector } from 'recoil';
import { request } from 'src/utils/axios';

type UserLoginProviderType = 'kakao' | 'engineeo' | null;

export const UserLoginProvider = atom<UserLoginProviderType>({
    key: 'UserLoginProvider',
    default: null,
});

export const getUserNickName = selector({
    key: 'getUserNickName',
    get: async () => {
        const userIdx = localStorage.getItem('idx');
        if (!userIdx) return;

        const res = await request('GET', `/users/${userIdx}/userInfo`);
        if (res.isSuccess) return res?.result?.userNickname;
        else return '';
    },
});

export const getUserProvider = selector({
    key: 'getUserProvider',
    get: async () => {
        const userIdx = localStorage.getItem('idx');
        if (!userIdx) return;

        const res = await request('GET', `/users/${userIdx}/userInfo`);
        if (res.isSuccess) return res?.result?.provider;
        else return '';
    },
});
