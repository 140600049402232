import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalDispatch, openedModalState } from 'src/recoil/modal/modalState';

const Modals = (): JSX.Element[] => {
    const openedModals = useRecoilValue(openedModalState);
    const setOpenedModals = useSetRecoilState(openedModalState);
    const { close } = useRecoilValue(modalDispatch);

    const renderedModals = openedModals.map((modal, idx) => {
        const { Component, props } = modal;
        const handleClose = () => {
            close(Component, setOpenedModals);
        };

        return <Component key={idx} {...props} handleClose={handleClose} />;
    });

    return renderedModals;
};

export default Modals;
