/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { Method, AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import getErrorMessage from './getErrorMessage';
const TARGET_IDX = 1;

export const ORIGIN = ['https://api.examo.kr/api', 'https://old-api.examo.link/api'];
const origin = window.location.origin;
const rvbIdp =
    window.location.host.split('.')[0].includes('localhost') ||
    window.location.host.split('.')[0] === 'old'
        ? 'linkareer'
        : window.location.host.split('.')[0] || 'linkareer';

const SERVER_DEPOLY_URL = origin.includes('examo.link')
    ? 'https://api.examo.link/api'
    : 'https://api.revibio-cbt.com/api';

class NetworkError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NetworkError';
    }
}

export const request = async (method: Method | undefined, url: string, data?: any) => {
    const reqHeader = {
        'x-access-token': localStorage.getItem('engineeo_token'),
        'rvb-idp': rvbIdp,
    };
    document.body.style.cursor = 'wait';

    return axios({
        method,
        url:
            process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
                ? ORIGIN[TARGET_IDX] + url
                : SERVER_DEPOLY_URL + url,
        data,
        headers: reqHeader,
    })
        .then((res) => {
            document.body.style.cursor = 'default';
            return res.data;
        })
        .catch((err) => {
            if (err.response?.status >= 500) {
                const { method, url, params, headers } = err.config;
                const { data, status } = err.response;

                Sentry.setContext('API Request Detail', {
                    method,
                    url,
                    params,
                    data,
                    headers,
                });

                Sentry.setContext('API Response Detail', {
                    status,
                    data,
                });

                Sentry.withScope((scope) => {
                    scope.setTag('type', 'api');
                    scope.setLevel('error');

                    Sentry.captureException(new NetworkError(getErrorMessage(err)));
                });
            }
            throw err;
        });
};

export const requestFile = async (method: Method | undefined, url: string, data?: any) => {
    const reqHeader = {
        'x-access-token': localStorage.getItem('engineeo_token'),
        'rvb-idp': rvbIdp,
    };
    document.body.style.cursor = 'wait';
    return axios({
        headers: reqHeader,
        method,
        url:
            process.env.NODE_ENV === 'development'
                ? ORIGIN[TARGET_IDX] + url
                : SERVER_DEPOLY_URL + url,
        data,
        responseType: 'blob',
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.style.cursor = 'default';
            link.remove();
        })
        .catch((err) => console.log(err));
};

export const uploadFile = async (method: Method | undefined, url: string, data?: any) => {
    const reqHeader = {
        'x-access-token': localStorage.getItem('engineeo_token'),
        'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    data.forEach((item: any) => {
        formData.append('files', item);
    });

    document.body.style.cursor = 'wait';
    return axios({
        headers: reqHeader,
        method,
        url:
            process.env.NODE_ENV === 'development'
                ? ORIGIN[TARGET_IDX] + url
                : SERVER_DEPOLY_URL + url,
        data: formData,
    })
        .then((res) => {
            document.body.style.cursor = 'default';
            return res.data;
        })
        .catch((err) => console.log(err));
};

/*----------------------------------------------------*/

const APP_TARGET_IDX = 0;
const APP_API_ORIGIN = ['https://api.examo.link'];

const APP_API_PATH =
    origin === 'https://examo.kr' || origin === 'https://old.examo.kr'
        ? 'https://api.examo.kr'
        : 'https://api.examo.link';

export const app_request = async (
    method: Method | undefined,
    url: string,
    data?: any,
    header?: any
) => {
    const reqHeader = {
        'x-access-token': localStorage.getItem('engineeo_token'),
        'Authorization': `Bearer ${localStorage.getItem('engineeo_token')}`,
    };
    document.body.style.cursor = 'wait';

    return axios({
        method,
        url:
            process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
                ? APP_API_ORIGIN[APP_TARGET_IDX] + url
                : APP_API_PATH + url,
        data,
        headers: header ? header : reqHeader,
    })
        .then((res) => {
            document.body.style.cursor = 'default';
            return res.data;
        })
        .catch((err) => {
            document.body.style.cursor = 'default';
            console.log(err);
            throw err;
        });
};

export const app_uploadFile = async (
    method: Method | undefined,
    url: string,
    formData?: FormData
) => {
    const reqHeader = {
        'x-access-token': localStorage.getItem('engineeo_token'),
        'Content-Type': 'multipart/form-data',
    };
    document.body.style.cursor = 'wait';

    return axios({
        headers: reqHeader,
        method,
        url: url,
        data: formData,
    })
        .then((res) => {
            document.body.style.cursor = 'default';
            return res.data;
        })
        .catch((err) => {
            document.body.style.cursor = 'default';
            console.log(err);
            throw new Error(err);
        });
};

export const app_blobFile = async (
    method: Method | undefined,
    url: string,
    data?: any,
    headers?: any
) => {
    const reqUrl =
        process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
            ? APP_API_ORIGIN[APP_TARGET_IDX] + url
            : APP_API_PATH + url;
    return fetch(reqUrl, {
        body: data,
        headers,
        method,
    })
        .then((res) => {
            document.body.style.cursor = 'default';
            return res.blob();
        })
        .catch((err) => {
            document.body.style.cursor = 'default';
            console.log(err);
        });
};
