/**
 * 특정 페이지에서 채널톡을 비활성화 합니다.
 */
export function isDisabledChatbotPath(currentPath: string) {
    const disabledPath = [
        'admin',
        '/cbt/practice',
        '/myStudy/review/problem',
        '/cbt/onequestions',
        '/publicEnt/onequestions',
        '/publicEnt/cbt',
        '/cbt/practice/score/solution',
        '/introduce',
    ];
    return disabledPath.some((path) => currentPath.includes(path));
}
