import { captureException, withScope } from '@sentry/react';
import React, { ErrorInfo, ReactNode } from 'react';
import getErrorMessage from 'src/utils/getErrorMessage';
interface RenderFallbackProps<ErrorType extends Error = Error> {
    error: ErrorType;
    reset: (...args: unknown[]) => void;
}

type RenderFallbackType = <ErrorType extends Error>(
    props: RenderFallbackProps<ErrorType>
) => ReactNode;

interface Props {
    children?: ReactNode;
    errorFallback: RenderFallbackType;
    refetch?: () => void;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

const initialState = { hasError: false, error: null };

class BoundaryError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'BoundaryError';
    }
}

class ErrorBoundary extends React.Component<Props, State> {
    public state: State = initialState;

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    resetErrorBoundary = () => {
        this.props.refetch?.();
        this.setState(initialState);
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        withScope((scope) => {
            scope.setTag('type', 'boundary');
            scope.setLevel('warning');

            captureException(new BoundaryError(getErrorMessage(error)), {
                extra: errorInfo as any,
            });
        });
    }

    public render() {
        const { children, errorFallback } = this.props;
        const { hasError } = this.state;
        const error = this.state.error as Error;
        if (hasError) {
            return errorFallback({
                error,
                reset: this.resetErrorBoundary,
            });
        }

        return children;
    }
}

export default ErrorBoundary;
