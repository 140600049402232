import { useState, useEffect, Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { request } from 'src/utils/axios';
import * as router from 'src/routes';
import ErrorBoundary from 'src/components/_hoc/ErrorBoundary';
import { EngineeoLoader } from 'src/utils/EngineeoLoader';
import 'src/components/atoms/cbt/katex.scss';
import 'react-datepicker/dist/react-datepicker.css';

import RootErrorBoundary from 'src/components/organisms/RootErrorBoundary';
import { useSetRecoilState } from 'recoil';
import { UserLoginProvider } from './recoil/account/states';
import { withProfiler } from '@sentry/react';
// 추후에 모달 띄울 때 주석 제거
import ReactModal from 'react-modal';
import { useModals } from './hooks/useModals';
import Modals from './components/organisms/Modals/Modals';
ReactModal.setAppElement('#root');

import Cookies from 'js-cookie';
import useSearchParams from './hooks/useSearchParams';
import NaverAnalyticsConfig from './naverAnalyTicsConfig';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import useChannelTalk from './utils/ChannelTalk/hooks/useChannalTalk';

import SEOMetaTag from './pages/SEOMetaTag';

const REACT_QUERY_CONFIG = {
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 60 * 60 * 24 * 1000,
            refetchOnWindowFocus: false,
            suspense: true,
            throwOnError: true,
            retry: 3,
        },
    },
};

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

function App() {
    const { openModal } = useModals();

    const location = useLocation();
    const navigate = useNavigate();
    const params = useSearchParams();
    const [memberHash, setMemberHash] = useState();
    const setUserLoginProvider = useSetRecoilState(UserLoginProvider);

    const [userProfile, setUserProfile] = useState({
        age: undefined,
        createdAt: '',
        dateOfBirth: '',
        email: '',
        memberId: '',
        mobileNumber: '',
        name: '',
        nickname: '',
        currentLocation: '',
        validProduct: '',
        invalidProduct: '',
    });

    const confirm = async () => {
        const token = Cookies.get('token');
        if (!token) {
            if (localStorage.getItem('engineeo_token')) {
                localStorage.removeItem('engineeo_token');
                localStorage.removeItem('idx');
                alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
            }
            return;
        }
    };

    const initChannelTalk = async () => {
        const res = await request('GET', `/users/${localStorage.getItem('idx')}/profile`);
        if (res.isSuccess) {
            setUserProfile(res.result);
            setUserLoginProvider(res.result.provider);
        }
    };

    useEffect(() => {
        confirm();
    }, []);

    useEffect(() => {
        if (memberHash) {
            initChannelTalk();
        }
    }, [memberHash]);

    useChannelTalk();

    useEffect(() => {
        if (params.access_token) {
            Cookies.set('token', params.access_token.toString(), { expires: 1 / 4 });
            localStorage.setItem('engineeo_token', params.access_token.toString());
            params.refresh_token &&
                localStorage.setItem('engineeo_refresh_token', params.refresh_token.toString());
            params.userIdx && localStorage.setItem('idx', params.userIdx.toString());
        }

        if (params.nextUrl !== undefined && params.nextUrl !== 'null') {
            if (window.location.pathname === '/') window.location.href = params.nextUrl.toString();
        }
    }, []);

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <ErrorBoundary errorFallback={() => <RootErrorBoundary />}>
                    <NaverAnalyticsConfig />

                    <Suspense fallback={<EngineeoLoader />}>
                        <SEOMetaTag />
                        <Routes>
                            {/* admin */}
                            <Route path="/admin" element={<router.Admins />} />
                            {/* 404 */}
                            <Route path="*" element={<router.NotFound />} />
                        </Routes>
                    </Suspense>
                    {Modals()}
                </ErrorBoundary>
            </QueryClientProvider>
        </>
    );
}

export default withProfiler(App);
