import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/transition.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import { BrowserRouter } from 'react-router-dom';
import { disableReactDevTools } from './utils/disableReactDevTools';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';

Sentry.init({
    dsn:
        process.env.NODE_ENV === 'production'
            ? 'https://81fe80b006aa4c729a5fbfb01287fa70@o4504631823761408.ingest.sentry.io/4504631825530880'
            : '', // 복사한 DSN 값을 여기에 붙여넣으세요.
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.3, // 프로덕션 환경에서는 수치를 더 낮춰야 합니다.
});

if (process.env.NODE_ENV === 'production') disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <RecoilRoot>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </RecoilRoot>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
// reportWebVitals();
