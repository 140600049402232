const NAVER_ANALYTICS_ID = '1cb6db32ba318f0';

export const naverAnalyticsConfigScript = `
  try {
    if(!wcs_add) var wcs_add = {}; 
    wcs_add["wa"] = "${NAVER_ANALYTICS_ID}";
    wcs_do && wcs_do();
  } catch(e) {
  }
`;
